<!--
 * @Author: wangwei
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2021-01-25 14:31:14
 * @LastEditors: Please set LastEditors
 * @Description: 银行卡
 * @FilePath: /java-pc/src/views/member/money/recharge.vue
-->
<template>
  <div class="sld_bankCard">
    <MemberTitle memberTitle="我的银行卡"></MemberTitle>
    <div class="sld_bankCard_con">
      <div class="bank_top">
        <div>开户信息</div>
        <div @click="addBankCard" v-if="!opening">立即开户</div>
      </div>
      <div class="bank_content" v-if="opening">
        <ul>
          <li>
            <span>真实姓名：</span>
            <el-input v-model="store.state.memberInfo.corpName" :disabled="true"></el-input>
          </li>
          <li>
            <span>手机号码：</span>
            <el-input v-model="store.state.memberInfo.memberMobile" :disabled="true"></el-input>
          </li>
          <li>
            <span>身份证号：</span>
            <el-input v-model="store.state.memberInfo.corpCode" :disabled="true"></el-input>
          </li>
        </ul>
      </div>
      <div class="bank_content1" v-else>
        <img src="../../../assets/member/empty_goods.png" />
        <div>暂无相关数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import MemberTitle from '../../../components/MemberTitle';
import { useRouter } from 'vue-router';
import { onMounted,ref,getCurrentInstance } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'
import { useStore } from 'vuex';
export default {
  name: "Recharge",
  components: {
    MemberTitle
  },
  setup() {
    const router = useRouter()
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const addBankCard = () => {
      if (store.state.memberInfo.memberTrueName) {
        router.push({
        path: '/member/AccountNow2'
      })
      } else {
        router.push({
        path: '/member/AccountNow'
      })
      }
     
    }
    
    // 解绑
    const unbinding = () => {
      ElMessageBox.confirm(
        '是否确定解绑帮该银行卡',
        '提示',
        {
          confirmButtonText: '取消',
          cancelButtonText: '确定',
          confirmButtonClass: 'classDilog',
          cancelButtonClass: 'classDilog',
          // type: 'warning',
          center: true,
        }
      )
        .then(() => {
          ElMessage({
            type: 'success',
            message: 'Delete completed',
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: 'Delete canceled',
          })
        })
    }
    const getInfo=()=>{
      let param = {}
      proxy.$get("api/shopp/member/detail",{uscUserId:store.state.memberInfo.uscUserId}).then(res => {
          if (res.code == 200) {
            if(!res.data.createWallet){
              opening.value = false
            }else if(res.data.createWallet==1){
              opening.value = true
            }
          }
      })
    }
    const opening = ref(false)
    onMounted(() => {
        getInfo()
    });
    return {
      unbinding,
      addBankCard,
      store,
      getInfo,
      opening
    };
  }
};
</script>

<style lang="scss" scoped>
.classDilog {
  float: right;
  margin-left: 10px;
  margin-top: 10px;
}

.sld_bankCard {
  width: 1020px;
  float: left;
  margin-left: 10px;
  .bank_content1 {
    margin-top: 20px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      margin-top: 20px;
    }
  }
  .bank_content {
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    padding-left: 36px;
    height: 350px;
    display: flex;
    align-items: center;
    ul {
      li {
        display: flex;
        align-items: center;
        color: #1C1C1C;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 30px;
        span {
          display: inline-block;
          width: 120px;
          text-align: right;
        }
      }
    }   
  }
  .unbinding {
    height: 40px;
    line-height: 40px;
    text-align: right;
    width: 307px;
    color: rgba(30, 119, 240, 1);
    cursor: pointer;
  }

  .sld_bankCard_con {
    background: #fff;
    height: 544px;
    padding: 0 20px;
  }
}

.bank_top {
  height: 70px;
  // background-color: red;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    &:nth-child(1) {
      line-height: 70px;
      color: #1C1C1C;
      font-size: 16px;
      font-weight: 600;
      text-align: left;
    }

    &:nth-child(2) {
      background-color: $colorMain;
      color: #FFFFFF;
      line-height: 30px;
      width: 80px;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
</style>